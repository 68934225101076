@import '../tdi/css/lib';
@import 'tangelo-custom-clp.scss';
@import 'design-workarounds.scss';

/* default content tangelo.scss:*/
a {cursor:pointer;}
em {font-style:italic;}
tr {height:20px;}
section img {max-width:100%;height:auto;}

.marginbottom- {
  &none { margin-bottom:0; }
}
span.highlight {
  background-color:#d6ecff;
}

@each $list-style in (
  none, square, circle, disc,                                                       // ul list styles
  decimal, decimal-leading-zero, lower-alpha, upper-alpha, lower-roman, upper-roman // ol list-styles
) {
  .list-style-#{$list-style} {
    list-style: $list-style;
  }
}

.title-line {
   &.title-banner {
    background-repeat:no-repeat !important;
    background-size: cover !important;
    .container-fluid {
      position: relative !important;
      height: 200px !important;
      .container-title {
        position: absolute !important;
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
        left: 50% !important;
        width:100%;
      }
    }
  }
  &.title-quote h1{
    text-align: left !important;
  }
}
@media (min-width: 991px) {
  .title-line.title-banner .container-fluid {
    height: 302px !important;
  }
}

.items-about {
 margin-top: 70px;
}

.caption {
  font-style: italic;
}

.lang-zh .caption {
  font-style: normal;
}

.top-line a {
  color: #ffffff;
}

#data-table {
  h5 {
      color: #3ac2c0;
      font-size: 20px;
      font-weight: normal;
      margin-bottom: 15px;
  }
}
.wrap-single-studies .img {
    margin-bottom: 20px;
    margin-top: 20px;
}

.title-line.pad-md .container-fluid {
    padding-bottom: 90px;
}

table.data-table tr:first-of-type th:first-of-type p {
  display: none;
}

@media (max-width: 767px) {
  .title-line.pad-md .container-fluid {
      padding-top: 75px;
  }
  .title-line h1 {
    font-size: 28px;
  }
}

.main-menu li {
  vertical-align: top;

  a {
    height: 69px;
  }
}

.table-wrap.header-white th {
  color: #fff;
}

//ensure svgs get scaled up
.cms-image * {
  width: 100vw;
  max-width: 100%;
}
// commit dea8baf 2020-01-09

.tdi--noselect {
  -webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;-o-user-select:none;user-select:none;
}

.tdi--text-overflow-wrapper {
  overflow: auto;
  max-width: 100%;
  > table {
    border-collapse: separate;
  }
}

#tdi--tooltip {
  text-align: center;
  color: #fff;
  background: #111;
  position: absolute;
  z-index: 100;
  padding: 15px;
  font-size: 90%;

  &:after {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #111;
    content: '';
    position: absolute;
    left: 50%;
    bottom: -10px;
    margin-left: -10px;
  }
  &.top:after {
    border-top-color: transparent;
    border-bottom: 10px solid #111;
    top: -20px;
    bottom: auto;
  }

  &.left:after {
    left: 10px;
    margin: 0;
  }

  &.right:after {
    right: 10px;
    left: auto;
    margin: 0;
  }
}
